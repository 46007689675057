<template>
  <v-card class="d-flex mt-6">
    <v-card-text>
      <v-row>
        <v-col :cols="`${!isMobile ? 4 : 12}`">
          <v-card outlined class="flex-card" height="100%">
            <v-card-text>
              <img
                lazy-src="@/assets/images/logo.png"
                max-height="250"
                max-width="250"
                src="@/assets/images/logo.png"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="`${!isMobile ? 8 : 12}`">
          <h6>
            <span class="font-weight-medium">Build Your Own Case Study</span>
            <br />
            <b
              >A Glimpse at Optimal T2D Management: Enhancing Outcomes with
              GLP-1 RAs</b
            >
          </h6>
          <h5 class="pt-5 pb-5 font-weight-medium">
            You have completed this lesson.
            <br /><br />
            <b
              >You may close this window/tab and proceed with course
              requirements for credit.</b
            >
          </h5>
          <template>
            <div>
              <v-alert
                type="info"
                color="#82A9B0"
                icon="mdi-account-voice"
                border="left"
                prominent
                shaped
              >
                Your feedback is important to develop impactful education in the
                future. Please complete the course evaluation and join us for
                future <b>Build Your Own Case Study</b>
                Activities.
              </v-alert>
            </div>
          </template>
          <div class="row justify-center">
            <email-notes v-if="user" :user="user"></email-notes>
            <print-notes v-if="user" :user="user" class="ml-2"></print-notes>

            <v-btn
              outlined
              color="orange"
              :rounded="true"
              class="border ml-5 mt-1"
              @click="returnToSteps"
              >return to case</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import EmailNotes from "/src/components/email/EmailNotes.vue";
import PrintNotes from "/src/components/pdf/PrintNotes.vue";
export default {
  name: "Credit",
  components: {
    EmailNotes,
    PrintNotes,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  beforeMount() {
    this.trackingAction("Credits opened");
  },
  beforeDestroy() {
    this.trackingAction("Credits closed");
  },
  methods: {
    ...mapMutations(["setShowCredit"]),
    ...mapActions(["trackingAction"]),
    returnToSteps() {
      this.setShowCredit(false);
    },
  },
};
</script>
