<template>
  <v-row v-if="introduction">
    <v-col sm="4" md="4" lg="4" xl="4">
      <v-card outlined class="flex-card" height="100%">
        <v-card-text>
          <v-img
            lazy-src="@/assets/images/logo.png"
            max-height="250"
            max-width="250"
            src="@/assets/images/logo.png"
            class="m-auto"
          ></v-img>
          <h6 class="pt-5 pb-5 font-weight-medium">
            Welcome to<br />
            <b
              >A Glimpse at Optimal T2D Management: Enhancing Outcomes with
              GLP-1 RAs</b
            >
          </h6>
          <h6>Here's what you'll do:</h6>
          <p class="pb-4 font-weight-medium">
            <span
              >1. Treat up to 3 patients<br />2. Spend some time in the
              Library<br />3. Collect some credit!</span
            >
          </p>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <v-btn
            class="white--text caption font-weight-bold"
            large
            elevation="2"
            rounded
            color="orange"
            @click="avatarArea()"
          >
            Start treating patients
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col sm="8" md="8" lg="8" xl="8">
      <player :video="video" />

      <h5 class="pt-5 pb-15 text-center">
        When you complete the video, click Start Treating Patients.
      </h5>
      <p class="text-center font-weight-bold" style="font-size:1.4em;">
        Best experienced on
        <br />
        <v-icon large color="orange">
          mdi-google-chrome
        </v-icon>
        Google Chrome
      </p>
    </v-col>
  </v-row>

  <v-row v-else>
    <v-col
      v-if="subStep.hasLeftSection"
      sm="3"
      md="3"
      lg="3"
      xl="3"
      align-self="center"
      class="d-flex justify-center"
    >
      <v-card class="flex-card" height="100%">
        <v-card-title v-if="subStep.leftSection.hasTitle">
          {{ subStep.leftSection.title }}
        </v-card-title>
        <v-card-subtitle v-if="subStep.leftSection.hasSubTitle">
          {{ subStep.leftSection.subtitle }}
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <v-row v-for="content in subStep.leftSection.content">
              <v-col
                v-if="content.type === 'image'"
                class="d-flex justify-center"
              >
                <v-img
                  :lazy-src="content.url"
                  max-height="200"
                  max-width="200"
                  contain
                  :src="content.url"
                ></v-img>
              </v-col>
              <v-col
                v-else="content.type === 'text'"
                class="d-flex justify-center"
              >
                <p
                  class="pt-5 pb-5 font-weight-medium"
                  v-html="content.text"
                ></p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text caption font-weight-bold"
            large
            elevation="2"
            rounded
            color="orange"
            @click="randomAvatar()"
          >
            Use the Randomizer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card class="flex-card" height="100%">
        <v-card-title v-if="subStep.mainSection.hasTitle">
          {{ subStep.mainSection.title.toUpperCase() }}
        </v-card-title>

        <v-card-subtitle v-if="subStep.mainSection.hasSubTitle">
          {{ subStep.mainSection.subtitle }}
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text v-if="subStep.mainSection.hasContent">
          <!-- AVATAR SELECTION MODULE -->
          <v-card
            outlined
            tile
            color="transparent"
            v-if="subStep.mainSection.content.type === 'selector'"
          >
            <v-card-text>
              <h6>
                You have 2 options when building your own case:
              </h6>
              <ul class="mb-5">
                <li>
                  <span style="font-weight:bold;">OPTION 1:</span>
                  Select a patient below and click Continue to fully customize
                  the patient experience.
                </li>
                <li>
                  <span style="font-weight:bold;">OPTION 2:</span>
                  Click the
                  <span style="color: #ff9800;font-weight:bold;"
                    ><b>RANDOMIZER</b></span
                  >
                  button, then click Continue to have the system build a patient
                  for you.
                </li>
              </ul>
              <v-row>
                <v-col
                  cols="4"
                  v-for="avatar in subStep.mainSection.content.avatars"
                  class="d-flex justify-center"
                >
                  <v-card
                    max-height="150"
                    max-width="100"
                    color="transparent"
                    @click="selectAvatar(avatar.id, false)"
                  >
                    <v-img
                      :lazy-src="avatar.url"
                      max-height="150"
                      max-width="100"
                      contain
                      :src="avatar.url"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Player from "/src/components/player/Player.vue";

export default {
  name: "StepAvatar",
  components: {
    Player,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      video: {
        id: "51usxpb0f1",
      },
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
    }),
    avatarList() {
      return this.steps[0].subSteps[0].mainSection.content.avatars;
    },
  },
  created() {
    this.setIntroduction(true);
    this.clearAvatar();
  },
  methods: {
    ...mapMutations(["setAvatar", "setIntroduction"]),
    ...mapActions(["trackingAction"]),
    clearAvatar() {
      this.setAvatar({});
    },
    avatarArea() {
      this.setIntroduction(false);
    },
    selectAvatar(avatarSelected, random = false) {
      const avatarSelectedIndex = avatarSelected - 1;
      const avatar = {
        random,
        id: avatarSelected,
        url: require("@/assets/images/avatars/avatar_" +
          avatarSelected +
          ".jpg"),
        realUrl: require("@/assets/images/avatars/avatar_real_" +
          avatarSelected +
          ".jpg"),
        bio: this.avatarList[avatarSelectedIndex].bio,
        name: this.avatarList[avatarSelectedIndex].name,
        age: this.avatarList[avatarSelectedIndex].age,
        gender: this.avatarList[avatarSelectedIndex].gender,
        description: this.avatarList[avatarSelectedIndex].avatarDescription,
        location: this.avatarList[avatarSelectedIndex].location,
        SDoHnoVideo: this.avatarList[avatarSelectedIndex].urlVideoSDoHNo,
      };
      this.setAvatar(avatar);
    },
    randomAvatar() {
      let min = Math.ceil(1);
      let max = Math.floor(9);
      let random = Math.floor(Math.random() * (max - min + 1)) + min;
      this.selectAvatar(random, true);
      this.trackingAction("Use Randomizer Button Pressed");
    },
  },
};
</script>
