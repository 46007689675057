<template>
  <step-template :subStep="subStep">
    <v-card-text>
      <v-row>
        <v-row v-if="bannerSrc">
          <v-col>
            <img
              :lazy-src="bannerSrc"
              contain
              :src="bannerSrc"
              style="margin: 0 auto;"
            />
          </v-col>
        </v-row>
        <v-row v-if="title">
          <v-col>
            <h5 class="pb-5 px-2 text-center text-break" v-html="title"></h5>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <h4
              class="pb-5 text-center"
              v-html="subStep.mainSection.content.mainText"
            ></h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="computedCols"
            v-for="button in _buttons"
            class="d-flex justify-center"
          >
            <v-card outlined tile color="transparent" @click="select(button)">
              <img
                v-if="button.url"
                class="img-fluid"
                :style="getButtonStyle(button.style)"
                :lazy-src="button.url"
                contain
                :src="button.url"
              />
              <v-btn v-else color="warning" rounded>
                {{ button.name }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
      <v-row v-if="!avatar.random">
        <v-col v-if="selectedButton" class="mt-20 d-flex justify-center">
          <h4>You selected: {{ selectedButton.name }}</h4>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="mt-20 d-flex justify-center">
          <h4>
            The
            <span style="color:#ff9800;"><b>RANDOMIZER</b></span>
            has selected {{ selectedButton.name }}.
          </h4>
        </v-col>
      </v-row>
    </v-card-text>
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-buttons'
 - update the row field component_props as a JSON like:
{
  "variables": { "vmodel": "CUSTOM_VARIABLE_NAME" },
  "buttons": [
    { "value": "CUSTOM_VARIABLE_VALUE1", "name": "CVD", "url": "/img/Comorbidity_CVD.4f230a8d.jpg" },
    { "value": "CUSTOM_VARIABLE_VALUE2", "name": "CKD", "url": "/img/Comorbidity_CKD.bfaecdfc.jpg" },
    {
      "value": "CUSTOM_VARIABLE_VALUE3",
      "name": "PCOS",
      "url": "/img/Comorbidity_PCOS.e12a30a2.jpg",
      "style": "background-color: #17a8de; border-radius: 25px;",
      "hide": { "avatar.gender": ["Male", "Transfemale"] }
    },
    OR
    { "value": true, "name": "Yes", "forceNextSubStepTo": 17 },
    { "value": false, "name": "No" }
  ],
  "borderColor":"green",
  "buttonsStyle": "",
  "bannerSrc": "/img/sdoh.487f9d98.jpg",
  "title": "Please select a Social Determinant of Health to explore."
}

(only 'variables' and 'buttons' are required)
 */

import { mapState, mapMutations, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepButtons",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    buttons: {
      type: Array,
      required: false,
      default: null,
    },
    buttonsStyle: {
      type: String,
      required: false,
      default: "",
    },
    borderColor: {
      type: String,
      required: false,
      default: "red",
    },
    bannerSrc: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      _buttons: [],
      selectedButton: null,
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
      state: (state) => state,
    }),
    computedCols() {
      if (this._buttons.length === 2) {
        return 6;
      }
      return 4;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled", "setForceNextSubStepTo"]),
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.initButtons();
      this.loadPreviusSelectedChoice();
      this.selectRandom();
      if (!this.selectedButton) {
        this.setContinueButtonEnabled(false);
      }
    },
    /**
     * Buttons can be received in specific props or in the substep
     * Props get priority
     */
    initButtons() {
      if (this.buttons) {
        this._buttons = this.filterButtons(this.buttons);
      } else {
        this._buttons = this.subStep.mainSection.content.avatars;
      }
    },
    loadPreviusSelectedChoice() {
      const currentValue = this.caseData[this.variables.vmodel];
      this.selectedButton = this._buttons.find((button) => {
        if (button.value == currentValue) {
          return true;
        }
      });
      this.checkForceNextSubStepTo();
    },
    filterButtons(buttons) {
      return buttons.filter((button) => {
        if (button.hide) {
          for (const filterVariables in button.hide) {
            const filterVariable = filterVariables.split(".");
            const currentValue = this.state[filterVariable[0]][
              filterVariable[1]
            ];
            if (button.hide[filterVariables].indexOf(currentValue) !== -1) {
              return false;
            }
          }
        }
        return true;
      });
    },
    select(button) {
      this.setCaseDataValue({
        key: this.variables.vmodel,
        value: button.value,
        fullObject: button,
      });
      this.selectedButton = button;
      this.checkForceNextSubStepTo();
      this.setContinueButtonEnabled(true);
    },
    checkForceNextSubStepTo() {
      if (this.selectedButton && this.selectedButton.forceNextSubStepTo) {
        this.setForceNextSubStepTo(this.selectedButton.forceNextSubStepTo);
      } else {
        this.setForceNextSubStepTo(null);
      }
    },
    selectRandom() {
      if (!this.avatar.random) return;

      const r_number =
        Math.floor(Math.random() * (this._buttons.length - 1)) + 1;
      this.select(this._buttons[r_number]);
    },
    getButtonStyle(style) {
      let s =
        "object-fit: contain !important; max-width: 100% !important; max-height: 100% !important; width: auto !important; height: auto !important; max-width: inherit !important;";
      if (style) {
        s += style;
        return s;
      } else if (this.buttonsStyle) {
        s += this.buttonsStyle;
        return s;
      }

      return `background-color:white;border:2px solid ${this.borderColor}; border-radius: 25px; ${s}`;
    },
  },
};
</script>
