<template>
  <v-row v-if="!validatedUser"
    >Opps, something went wrong! <br />Try to contact administration.</v-row
  >
  <v-row v-else-if="!(contentLoaded && loading.caseProgress)">
    <Loading class="d-flex align-center mx-5" />
  </v-row>
  <div v-else-if="showCredit">
    <credit :isMobile="isMobile()" />
  </div>
  <div v-else-if="showLibrary">
    <library
      :steps="steps"
      :step="step"
      :subStep="subStep"
      :videos="videos"
      :isMobile="isMobile()"
    />
  </div>
  <div v-else>
    <v-row>
      <v-col
        :sm="dynamicComponentWidth"
        :md="dynamicComponentWidth"
        :lg="dynamicComponentWidth"
        :xl="dynamicComponentWidth"
        :class="cMainContent"
      >
        <component
          v-if="subStep"
          :key="dynamicComponentKey"
          :is="subStep.component"
          :steps="steps"
          :step="step"
          :subStep="subStep"
          :videos="videos"
          :avatarsCombinations="avatars_combinations"
          :isMobile="isMobile()"
          v-bind="componentProps"
        ></component>
      </v-col>
      <avatar-panel
        v-show="avatarPanelVisible"
        :steps="steps"
        :step="step"
        :subStep="subStep"
        :isMobile="isMobile()"
      ></avatar-panel>
    </v-row>
    <v-row v-show="!introduction" class="overflow-x-auto mx-1">
      <v-col v-if="!isMobile()" cols="12" class="d-flex justify-center">
        <stepper
          :steps="steps"
          :step="step"
          :subStep="subStep"
          :currentStep="currentStep"
        ></stepper>
      </v-col>
      <stepper
        v-else
        :steps="steps"
        :step="step"
        :subStep="subStep"
        :currentStep="currentStep"
        class="pb-12"
      ></stepper>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import {
  LS_KEY_UUID,
  LS_KEY_AVATAR,
  LS_KEY_CASE_DATA,
  LS_CURRENT_STEP,
  LS_CURRENT_SUBSTEP,
} from "/src/store/index.js";
import axios from "axios";
import AvatarPanel from "/src/components/steps/AvatarPanel.vue";
import Credit from "/src/components/card/Credit.vue";
import Library from "/src/components/card/Library.vue";
import Loading from "/src/components/base/Loading.vue";
import Stepper from "/src/components/steps/Stepper.vue";
import StepAvatar from "/src/components/steps/StepAvatar.vue";
import StepButtons from "/src/components/steps/StepButtons.vue";
import StepMultipleChoice from "/src/components/steps/StepMultipleChoice.vue";
import StepProfile from "/src/components/steps/StepProfile.vue";
import StepSlider from "/src/components/steps/StepSlider.vue";
import StepVideo from "/src/components/steps/StepVideo.vue";

const ALLOWED_PLATAFORMS = ["mli", "healio", "lms"];

export default {
  name: "Case",
  metaInfo: {
    title: "MLI",
  },
  components: {
    AvatarPanel,
    Credit,
    Library,
    Loading,
    Stepper,
    StepAvatar,
    StepButtons,
    StepMultipleChoice,
    StepProfile,
    StepSlider,
    StepVideo,
  },
  data() {
    return {
      loading: {
        steps: false,
        videos: false,
        avatar: false,
        caseProgress: false,
      },
      validatedUser: true,
      componentProps: null,
      steps: [],
      videos: [],
      avatars_combinations: [],
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
      showCredit: (state) => state.showCredit,
      showLibrary: (state) => state.showLibrary,
      user: (state) => state.user,
    }),
    contentLoaded() {
      return !(
        this.loading.steps &&
        this.loading.videos &&
        this.loading.avatar
      );
    },
    step() {
      if (this.steps.length < 1) {
        return null;
      }
      return this.steps.find((val) => {
        if (val.id === this.currentStep) {
          return val;
        }
      });
    },
    subStep() {
      if (!this.step) {
        return null;
      }
      return this.step.subSteps.find((val) => {
        if (val.id === this.currentSubStep) {
          return val;
        }
      });
    },
    avatarPanelVisible() {
      return !!this.avatar.name;
    },
    dynamicComponentWidth() {
      return this.introduction ? 12 : 8;
    },
    dynamicComponentKey() {
      return this.currentStep + "-" + this.currentSubStep;
    },
    cMainContent() {
      if (this.isMobile()) return "fit-content";
      else return "";
    },
  },
  watch: {
    subStep(val) {
      if (val) {
        this.loadSubStepComponentProps();
        this.trackAction(val);
      }
    },
    contentLoaded(val) {
      if (val) {
        this.loadProgress();
      }
    },
  },
  async created() {
    this.setupUser();
    this.validatedUser = this.isValidUser();
    if (this.validatedUser) {
      this.getSteps();
      this.getAvatar();
      this.getVideos();
    }
  },
  async mounted() {
    this.trackingAction("init");
  },
  methods: {
    ...mapMutations([
      "setUser",
      "setCurrentStep",
      "setCurrentSubStep",
      "setAvatar",
    ]),
    ...mapActions(["trackingAction", "setCaseData", "pushCaseData"]),
    setupUser() {
      this.setUser({
        uuid: this.$route.params.uuid,
        fName: this.$route.params.fname,
        lName: this.$route.params.lname,
        email: this.$route.params.email,
        platform: this.$route.params.platform,
        learnerType: this.$route.params.learnertype,
      });
    },
    isValidUser() {
      let valid = true;
      if (ALLOWED_PLATAFORMS.indexOf(this.user.platform) === -1) {
        this.trackingAction(
          `User validation error: tried to access with invalid plataform (${this.user.platform})`
        );
        valid = false;
      }

      const regex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
      if (!regex.test(this.user.uuid)) {
        this.trackingAction(
          `User validation error: tried to access with invalid uuid (${this.user.uuid})`
        );
        valid = false;
      }

      return valid;
    },
    async getSteps() {
      this.loading.steps = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/data`
        );
        this.steps = response.data;
      } catch (err) {
        console.log(err);
      }
      this.loading.steps = false;
    },
    async getAvatar() {
      this.loading.avatar = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/avatar_combinations`
        );
        this.avatars_combinations = response.data;
      } catch (err) {
        console.log(err);
      }
      this.loading.avatar = false;
    },
    async getVideos() {
      this.loading.videos = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/videos`
        );
        this.videos = response.data.data;
      } catch (err) {
        console.log(err);
      }
      this.loading.videos = false;
    },
    loadSubStepComponentProps() {
      if (this.subStep.component_props) {
        this.componentProps = JSON.parse(this.subStep.component_props);
      } else {
        this.componentProps = {};
      }
    },
    /**
     * First restore caseData from localStorage, after request an update from API
     */
    async restoreCaseData() {
      let localStoredCaseData = localStorage.getItem(LS_KEY_CASE_DATA);
      if (localStoredCaseData) {
        localStoredCaseData = JSON.parse(localStoredCaseData);
        this.setCaseData(localStoredCaseData);
      }

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/case-data/${this.user.uuid}`
        );

        if (response && response.status === 200 && response.data) {
          const caseData = response.data;
          if (caseData.uuid === this.user.uuid) {
            this.setCurrentStep(caseData.step);
            this.setCurrentSubStep(caseData.substep);
            this.setAvatar(JSON.parse(caseData.avatar));
            this.setCaseData(JSON.parse(caseData.case_data));
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async loadProgress() {
      const lastUuid = localStorage.getItem(LS_KEY_UUID);
      if (!lastUuid || lastUuid !== this.user.uuid) {
        // User changed, flush local stored data
        this.setCurrentStep(1);
        this.setCurrentSubStep(1);
        this.setAvatar({});
        this.setCaseData({});
        localStorage.setItem(LS_KEY_UUID, this.user.uuid);
      }

      if (localStorage.getItem(LS_CURRENT_STEP)) {
        const val = parseInt(localStorage.getItem(LS_CURRENT_STEP));
        this.setCurrentStep(val);
      }

      if (localStorage.getItem(LS_CURRENT_SUBSTEP)) {
        this.setCurrentSubStep(
          parseInt(localStorage.getItem(LS_CURRENT_SUBSTEP))
        );
      }

      let localStoredAvatar = localStorage.getItem(LS_KEY_AVATAR);
      if (localStoredAvatar) {
        localStoredAvatar = JSON.parse(localStoredAvatar);
        this.setAvatar(localStoredAvatar);
      }

      await this.restoreCaseData();

      setTimeout(() => {
        this.loading.caseProgress = true;
      }, 100);
    },
    trackAction(subStep) {
      this.trackingAction(`Moved to substep ${subStep.id}`);
      this.pushCaseData();
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.fit-content {
  min-width: fit-content;
}
</style>
