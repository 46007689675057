<template>
  <span>
    <v-btn
      v-if="!isPrinting"
      class="white--text caption font-weight-bold"
      large
      elevation="2"
      rounded
      color="blue"
      @click="printNotes()"
    >
      Print My Notes
    </v-btn>
    <v-btn
      v-else
      class="white--text caption font-weight-bold"
      large
      elevation="2"
      rounded
      color="blue"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-btn>
    <vue-html2pdf
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1100"
      :pdf-quality="1"
      :manual-pagination="false"
      :pdf-margin="40"
      :html-to-pdf-options="{
        pagebreak: { mode: ['avoid-all', 'legacy'] },
        filename: 'notes.pdf',
        margin: [20, 0, 10, 0],
      }"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <span style="margin-top: 100px;margin-left: 40%;">
            <img src="/img/logo.021085bd.png" alt="" width="200" />
          </span>
          <br />
          <span style="margin-left:15%;margin-right:15%"
            >Hi, {{ user.fName }} {{ user.lName }} </span
          ><br /><br />
          <span style="margin-left:15%;margin-right:15%"
            >Thank you for completing the course</span
          ><br /><br />
          <span style="margin-left:15%;margin-right:15%"
            >Build Your Own Case Study: A Glimpse at Optimal T2D</span
          ><br />
          <span style="margin-left:15%;margin-right:15%"
            >Management: Enhancing Outcomes with GLP-1 RAs</span
          ><br /><br />
          <span style="margin-left:15%;margin-right:15%"
            >Below are the notes that you entered during your time in the
            course</span
          ><br /><br />
          <span
            style="margin-left:15%;margin-right:15%"
            v-if="notesToPrint['Round 1'] !== undefined"
          >
            <h5 style="margin-left:15%;margin-right:15%">Round 1</h5>
            <span
              style="margin-left:15%;margin-right:15%"
              v-if="notesToPrint['Round 1']['A1C'] !== undefined"
            >
              <h6 style="margin-left:15%;margin-right:15%">A1C</h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['A1C']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['BMI'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">BMI</h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['BMI']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['Comorbidities'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Comorbidities</h6>
              <span
                v-for="(item, index) in notesToPrint['Round 1'][
                  'Comorbidities'
                ]"
              >
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['Profile'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Profile</h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['Profile']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['mb'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Modifiable Behavior
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['mb']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['sdoh'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Social Determinants of Health
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['sdoh']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['bias'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Unconsciousness Bias
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['bias']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['glp'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">GLP-1 RA</h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['glp']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['sideeffects'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Side Effects</h6>
              <span
                v-for="(item, index) in notesToPrint['Round 1']['sideeffects']"
              >
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 1']['library'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Library</h6>
              <span v-for="(item, index) in notesToPrint['Round 1']['library']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
          </span>
          <span
            style="margin-left:10%;margin-right:10%"
            v-if="this.notesToPrint['Round 2'] !== undefined"
          >
            <h6 style="margin-left:15%;margin-right:15%">Round 2</h6>
            <span v-if="notesToPrint['Round 2']['A1C'] !== undefined">
              <h6 style="margin-left:15%;margin-right:15%">A1C</h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['A1C']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['BMI'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">BMI</h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['BMI']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['Comorbidities'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Comorbidities</h6>
              <span
                v-for="(item, index) in notesToPrint['Round 2'][
                  'Comorbidities'
                ]"
              >
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['Profile'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Profile</h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['Profile']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['mb'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Modifiable Behavior
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['mb']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['sdoh'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Social Determinants of Health
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['sdoh']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['bias'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Unconsciousness Bias
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['bias']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['glp'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">GLP-1 RA</h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['glp']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['sideeffects'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Side Effects</h6>
              <span
                v-for="(item, index) in notesToPrint['Round 2']['sideeffects']"
              >
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 2']['library'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Library</h6>
              <span v-for="(item, index) in notesToPrint['Round 2']['library']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
          </span>
          <span
            style="margin-left:10%;margin-right:10%"
            v-if="this.notesToPrint['Round 3'] !== undefined"
          >
            <h6 style="margin-left:15%;margin-right:15%">Round 3</h6>
            <span v-if="notesToPrint['Round 3']['A1C'] !== undefined">
              <h6 style="margin-left:15%;margin-right:15%">A1C</h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['A1C']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['BMI'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">BMI</h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['BMI']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['Comorbidities'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Comorbidities</h6>
              <span
                v-for="(item, index) in notesToPrint['Round 3'][
                  'Comorbidities'
                ]"
              >
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['Profile'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Profile</h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['Profile']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['mb'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Modifiable Behavior
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['mb']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['sdoh'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Social Determinants of Health
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['sdoh']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['bias'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">
                Unconsciousness Bias
              </h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['bias']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['glp'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">GLP-1 RA</h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['glp']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['sideeffects'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Side Effects</h6>
              <span
                v-for="(item, index) in notesToPrint['Round 3']['sideeffects']"
              >
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
            <span v-if="notesToPrint['Round 3']['library'] !== undefined">
              <br />
              <h6 style="margin-left:15%;margin-right:15%">Library</h6>
              <span v-for="(item, index) in notesToPrint['Round 3']['library']">
                <li style="margin-left:15%;margin-right:15%">{{ item }}</li>
              </span>
            </span>
          </span>
        </section>
      </section>
    </vue-html2pdf>
  </span>
</template>

<script>
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "PrintNotes",
  components: {
    VueHtml2pdf,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      notes: [],
      notesToPrint: [],
      isPrinting: false,
    };
  },
  watch: {
    visible(newValue) {
      if (!newValue) {
        this.clearCurrentNote();
      }
    },
  },
  async beforeMount() {
    await this.notesForPrint();
  },
  methods: {
    hasStartedGeneration() {
      console.log("$hasStartedGeneration");
    },
    hasGenerated($event) {
      console.log("$hasGenerated", $event);
    },
    async onProgress($event) {
      console.log("$onProgress", $event);
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      let Year = new Date().getFullYear();
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          if (totalPages >= 3) {
            pdf.deletePage(1);
          }
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(11);
            pdf.setTextColor(150);
            pdf.text(
              "© " +
                Year +
                " Medical Learning Institute, Inc. All Rights Reserved",
              pdf.internal.pageSize.getWidth() * 0.25,
              pdf.internal.pageSize.getHeight() - 5
            );
          }
        })
        .save();
      console.log("done");
      this.isPrinting = false;
    },
    async notesForPrint() {
      let uuid = this.user.uuid;
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/notesForPrint`,
          { params: { uuid } }
        );
        this.notesToPrint = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    printNotes() {
      this.isPrinting = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped></style>
