<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <player v-if="user" :user="user" :video="_video" :key="videoKey" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="visible = false">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Player from "/src/components/player/Player.vue";
export default {
  name: "ModalVideo",
  components: {
    Player,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    user: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    video: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      _video: { id: null },
      videoKey: "",
      visible: false,
    };
  },
  watch: {
    video(val) {
      this.setVideo(val);
    },
  },
  beforeMount() {
    this.setVideo(this.video);
  },
  methods: {
    setVideo(video) {
      this._video = video;
      this.setVideoKey();
    },
    setVisible(value) {
      this.visible = value;
    },
    setVideoKey() {
      this.videoKey = "key_" + this._video.id;
    },
  },
};
</script>
