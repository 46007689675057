<template>
  <step-template :subStep="subStep">
    <div>
      <v-card class="mb-4">
        <v-card-title>
          <v-icon color="teal" class="pr-4">
            mdi-format-list-text
          </v-icon>
          Medical History
        </v-card-title>
        <v-card-text v-html="medicalHistory"></v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-title>
          <v-icon color="teal" class="pr-4">
            mdi-comment
          </v-icon>
          Patient Comment
        </v-card-title>
        <v-card-text v-html="patientComments" class="font-italic"></v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-title>
          <v-icon color="teal" class="pr-4">
            mdi-medical-bag
          </v-icon>
          Treatment Regimen
        </v-card-title>
        <v-card-text v-html="treatmentRegimen"></v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
          <v-icon color="teal" class="pr-4">
            mdi-test-tube
          </v-icon>
          Lab Results
        </v-card-title>
        <v-card-text v-html="labResults"></v-card-text>
      </v-card>
    </div>
  </step-template>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepProfile",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    avatarsCombinations: {
      type: Array,
    },
  },
  data() {
    return {
      medicalHistory: "loading...",
      patientComments: "loading...",
      treatmentRegimen: "loading...",
      labResults: "loading...",
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
    }),
    isRandomAvatar() {
      return this.avatar.random;
    },
    randomSelectedAvatarData() {
      return this.step.subSteps[0].mainSection.content.avatars[
        this.avatar.id - 1
      ];
    },
    selectedAvatarCombination() {
      return this.avatarsCombinations[
        parseFloat(this.caseData.comorbidityId) * 9 -
          9 +
          parseFloat(this.avatar.id) -
          1
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.loadMedicalHistory();
      this.loadPatientComments();
      this.loadTreatmentRegimen();
      this.loadLabResults();
    },
    loadMedicalHistory() {
      let details = "";
      if (this.isRandomAvatar) {
        details = this.randomSelectedAvatarData.medicalHistory;
      } else {
        details = this.selectedAvatarCombination.medicalHistory;
      }
      this.medicalHistory =
        details + "Comorbitity- " + this.caseData.comorbidityIdFullObject.name;
      this.setCaseDataValue({
        key: "medicalHistory",
        value: this.medicalHistory,
        trackAction: false,
      });
    },
    loadPatientComments() {
      if (this.isRandomAvatar) {
        this.patientComments = this.randomSelectedAvatarData.patientComments;
      } else {
        this.patientComments = this.selectedAvatarCombination.patientComments;
      }
      this.setCaseDataValue({
        key: "patientComments",
        value: this.patientComments,
        trackAction: false,
      });
    },
    loadTreatmentRegimen() {
      if (this.isRandomAvatar) {
        this.treatmentRegimen = this.randomSelectedAvatarData.treatmentRegimen;
      } else {
        this.treatmentRegimen = this.selectedAvatarCombination.regimen;
      }
      this.setCaseDataValue({
        key: "treatmentRegimen",
        value: this.treatmentRegimen,
        trackAction: false,
      });
    },
    loadLabResults() {
      let details = "";
      if (this.isRandomAvatar) {
        details = this.randomSelectedAvatarData.labResults;
        details = details.replace("Lab Results<br />", "");
      } else {
        details = this.selectedAvatarCombination.labResults;
      }
      this.labResults =
        "<br>A1C level - " +
        this.caseData.a1c +
        "<br>BMI level - " +
        this.caseData.bmi +
        "<br>" +
        details;
      this.setCaseDataValue({
        key: "labResults",
        value: this.labResults,
        trackAction: false,
      });
    },
  },
};
</script>
