<template>
  <v-col
    v-if="subStep"
    sm="4"
    md="4"
    lg="4"
    xl="4"
    align-self="center"
    class="justify-center"
  >
    <v-card
      class="flex-card"
      height="100%"
      v-if="subStep.rightSection.avatarSection"
    >
      <v-card-title
        style="word-wrap:break-word;"
        class="justify-center"
        v-show="showAvatar"
        v-if="subStep.rightSection.hasTitle"
      >
        {{ subStep.rightSection.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle
        v-show="showAvatar"
        v-if="subStep.rightSection.hasSubtitle"
      >
        {{ subStep.rightSection.subTitle }}
      </v-card-subtitle>
      <v-card-text v-show="showAvatar">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-card max-height="250" max-width="188">
                <v-img
                  :lazy-src="subStep.id === 1 ? avatar.url : avatar.realUrl"
                  max-height="250"
                  max-width="188"
                  width="188"
                  height="250"
                  contain
                  :src="subStep.id === 1 ? avatar.url : avatar.realUrl"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4 class="text-center mb-0">
                {{ avatar.name }}
              </h4>
              <h6 class="text-center ma-0">
                {{ avatar.description }}
              </h6>
              <h6 class="text-center ma-0" v-if="avatar.random">
                <small
                  ><span style="color:#ff9800;"><b>RANDOMIZER</b></span></small
                >
              </h6>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="pl-4 pr-2" v-html="avatar.bio"></p>

              <v-expansion-panels v-if="currentSubStep !== 1" accordion>
                <v-expansion-panel v-if="caseData.labResults" key="1">
                  <v-expansion-panel-header
                    >PATIENT DETAILS</v-expansion-panel-header
                  >
                  <v-expansion-panel-content key="1">
                    <v-expansion-panels accordion class="pt-4">
                      <v-expansion-panel key="1">
                        <v-expansion-panel-header disable-icon-rotate>
                          Medical History
                          <template v-slot:actions>
                            <v-icon color="teal">
                              mdi-format-list-text
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content key="1">
                          <p v-html="caseData.medicalHistory"></p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel key="2">
                        <v-expansion-panel-header disable-icon-rotate>
                          Patient Comment
                          <template v-slot:actions>
                            <v-icon color="teal">
                              mdi-comment
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content key="2">
                          <p v-html="caseData.patientComments"></p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel key="3">
                        <v-expansion-panel-header disable-icon-rotate>
                          Treatment Regimen
                          <template v-slot:actions>
                            <v-icon color="teal">
                              mdi-medical-bag
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content key="3">
                          <p v-html="caseData.treatmentRegimen"></p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel key="4">
                        <v-expansion-panel-header disable-icon-rotate>
                          Lab Results
                          <template v-slot:actions>
                            <v-icon color="teal">
                              mdi-test-tube
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content key="4">
                          <p v-html="caseData.labResults"></p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div v-show="$vuetify.breakpoint.smAndUp">
        <v-divider></v-divider>
        <v-card-actions
          v-show="showAvatar"
          class="justify-center"
          v-if="subStep.rightSection.hasActions"
        >
          <v-container v-if="!isMobile">
            <v-row no-gutters dense>
              <v-col class="d-flex justify-center">
                <notes
                  v-if="user"
                  :currentStep="currentStep"
                  :currentSubStep="currentSubStep"
                  :user="user"
                ></notes>

                <v-btn
                  v-show="backButton"
                  class="white--text caption font-weight-bold mr-2"
                  large
                  elevation="2"
                  rounded
                  color="orange"
                  @click="previousStep()"
                >
                  <span style="font-size: 1.4em;">Back</span>
                </v-btn>

                <v-btn
                  class="white--text caption font-weight-bold"
                  large
                  elevation="2"
                  rounded
                  :disabled="!continueButtonEnabled"
                  color="orange"
                  @click="nextStep()"
                >
                  <span style="font-size: 1.4em;">Continue</span>
                  <v-icon right>
                    mdi-arrow-right-circle-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </div>
    </v-card>
    <v-card v-else outlined tile class="flex-card" height="100%">
      <v-card-title v-if="subStep.rightSection.hasTitle">
        {{ subStep.rightSection.title }}
      </v-card-title>
      <v-card-subtitle v-if="subStep.rightSection.hasSubTitle">
        {{ subStep.rightSection.subtitle }}
      </v-card-subtitle>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(content, c) in subStep.rightSection.content"
              class="d-flex justify-center"
            >
              <v-img
                v-if="content.type === 'image'"
                :lazy-src="content.url"
                max-height="250"
                max-width="250"
                :src="content.url"
              ></v-img>
              <p
                v-if="content.type === 'text'"
                class="pt-5 pb-5 font-weight-medium"
                v-html="content.text"
              ></p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        v-for="action in subStep.rightSection.actions"
        class="d-flex justify-center"
      >
        <v-btn
          class="white--text caption font-weight-bold"
          large
          elevation="2"
          rounded
          color="orange"
          @click="actionsRightSection(action.action)"
        >
          {{ action.label }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-btn
      v-if="isMobile"
      v-show="backButton"
      style="z-index: 9;"
      color="orange"
      class="rounded-pill"
      dark
      fixed
      bottom
      left
      @click="previousStep()"
    >
      Back
    </v-btn>
    <notes
      v-if="user && isMobile"
      :currentStep="currentStep"
      :currentSubStep="currentSubStep"
      :user="user"
      :isFloating="true"
    ></notes>
    <v-btn
      v-if="isMobile"
      :disabled="!continueButtonEnabled"
      style="z-index: 9;"
      color="orange"
      class="rounded-pill"
      dark
      fixed
      bottom
      right
      @click="nextStep()"
    >
      Continue
      <v-icon>mdi-arrow-right-circle-outline</v-icon>
    </v-btn>
    <confirm
      ref="avatarConfirmModal"
      :showButton="false"
      :title="modal.title"
      :description="modal.description"
      buttonConfirmLabel="Yes"
      buttonConfirmColor="green"
      buttonCancelLabel="No"
      buttonCancelColor="black"
      :buttonConfirmCallback="
        () => {
          this.modal.onConfirm();
        }
      "
    ></confirm>
    <div v-if="isMobile" class="mobileBottom"></div>
  </v-col>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import Confirm from "/src/components/modals/Confirm.vue";
import Notes from "/src/components/modals/Notes.vue";

export default {
  name: "AvatarPanel",
  components: {
    Confirm,
    Notes,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
    subStep: {
      type: Object,
      required: true,
    },
    backButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAvatar: true,
      modal: {
        title: "",
        description: "",
        onConfirm: () => {
          console.log("not set");
        },
      },
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      introduction: (state) => state.introduction,
      caseData: (state) => state.caseData,
      continueButtonEnabled: (state) => state.continueButtonEnabled,
      currentStep: (state) => state.currentStep,
      currentSubStep: (state) => state.currentSubStep,
      forceNextSubStepTo: (state) => state.forceNextSubStepTo,
      user: (state) => state.user,
    }),
    subsStepsAmount() {
      if (this.step && this.step.subSteps) {
        return this.step.subSteps.length;
      }
      return 0;
    },
    stepsAmount() {
      if (this.steps) {
        return this.steps.length;
      }
      return 0;
    },
  },
  methods: {
    ...mapMutations([
      "setContinueButtonEnabled",
      "setCurrentStep",
      "setCurrentSubStep",
      "setIntroduction",
      "setForceNextSubStepTo",
      "setShowCredit",
    ]),
    ...mapActions(["setCaseData"]),
    previousStep() {
      this.setForceNextSubStepTo(null);
      if (this.currentStep === 1 && this.currentSubStep === 1) {
        this.setIntroduction(true);
      } else if (this.currentSubStep <= 1) {
        console.log(
          "previousStep this.currentSubStep <= 1",
          "no more substeps"
        );
      } else {
        this.setCurrentSubStep(this.currentSubStep - 1);
      }
      this.setContinueButtonEnabled(true);
      this.scrollToTop();
    },
    nextStep() {
      if (this.forceNextSubStepTo) {
        const goToSubstep = this.forceNextSubStepTo;
        this.setForceNextSubStepTo(null);
        this.setCurrentSubStep(goToSubstep);
      } else if (this.currentSubStep < this.subsStepsAmount) {
        this.setCurrentSubStep(this.currentSubStep + 1);
      } else if (this.currentSubStep === this.subsStepsAmount) {
        if (this.currentStep === this.stepsAmount) {
          this.modalShowCredits();
        } else {
          this.moveToNextStep();
        }
      }
      this.setContinueButtonEnabled(true);
      this.scrollToTop();
    },
    scrollToTop() {
      if (this.isMobile) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    moveToNextStep() {
      const nextStep = this.currentStep + 1;
      this.clearCurrentCaseData();
      this.setCurrentSubStep(1);
      this.setCurrentStep(nextStep);
    },
    clearCurrentCaseData() {
      this.setCaseData({});
    },
    modalShowCredits() {
      this.setShowCredit(true);
      // this.modal.title = "Claim Credit";
      // this.modal.description =
      //   "Are you sure you want to exit to Claim Credit? This will complete your lesson!";
      // this.modal.onConfirm = () => {
      //   this.setShowCredit(true);
      // };
      // this.$refs["avatarConfirmModal"].setVisible(true);
    },
  },
};
</script>

<style>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(46, 38, 38, 0.325) !important;
}

.mobileBottom {
  border-top: 1px solid #ffeac3;
  background-color: white;
  position: fixed;
  bottom: 0px;
  height: 60px;
  width: 94%;
  z-index: 8;
}
</style>
