<template>
  <step-template :subStep="subStep">
    <player
      :video="video"
      :startTime="startTime"
      :isMobile="isMobile"
      @onPlay="playerStateChanged"
      @onPause="playerStateChanged"
      @onStateChange="playerStateChanged"
      @onPercentChanged="watchedPercentChanged"
    />
    <div>
      <h5 class="pt-5 pb-4 ml-1 text-center text-break">
        <span v-if="videoWatched" class="text-yellow mb-4"
          >{{ cVideoWatchedMessage }}<br
        /></span>
        <span>
          {{ cMessage }}
        </span>
      </h5>
    </div>
    <v-row v-if="actionButtons" no-gutters dense class="mb-6 justify-center">
      <v-col v-if="!isMobile" class="d-flex justify-center">
        <v-btn
          v-for="(button, key) in actionButtons"
          :key="key"
          :class="`${button.class} mr-4`"
          large
          elevation="2"
          rounded
          :color="button.color"
          @click="customButtonClick(button)"
        >
          <span>{{ button.label }}</span>
        </v-btn>
      </v-col>
      <v-btn
        v-else
        v-for="(button, key) in actionButtons"
        :key="key"
        :class="`${button.class} mb-2`"
        large
        elevation="2"
        rounded
        :color="button.color"
        @click="customButtonClick(button)"
      >
        <span>{{ button.label }}</span>
      </v-btn>
    </v-row>
    <confirm
      ref="confirmModal"
      :showButton="false"
      :title="modal.title"
      :description="modal.description"
      buttonConfirmLabel="Yes"
      buttonConfirmColor="green"
      buttonCancelLabel="No"
      buttonCancelColor="black"
      :buttonConfirmCallback="
        () => {
          this.modal.onConfirm();
        }
      "
    ></confirm>
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-video'
 - update the row field component_props as a JSON like: 
        
* SIMPLE VIDEO
{ "video_id": "gqzfc4hfjy" }

OR
 
* VIDEO CHOOSE BY VARIABLE (like variables set in stepButtons.vue)
{
  "video_id": {
    "op": "value",
    "variable": "CUSTOM_VARIABLE_NAME",
    "values": {
      "VIDEO_ID": "CUSTOM_VARIABLE_VALUE1",
      "VIDEO_ID": "CUSTOM_VARIABLE_VALUE2",
      "VIDEO_ID": "CUSTOM_VARIABLE_VALUE3",
    }
  }
}

OR

* VIDEO CHOOSE BY VARIABLE, AND VARIABLE VALUE BETWEEN A RANGE (like variables set in stepSlider.vue)
{
  "video_id": {
    "op": "range",
    "variable": "CUSTOM_VARIABLE_NAME",
    "values": {
      "tmrf458pl1": { "min": 0, "max": 6.5 },
      "hfgqrdahv0": { "min": 6.6, "max": 7.9 },
      "9byj5tf894": { "min": 8, "max": 12 }
    }
  }
}

ALSO

* to use action buttons (can be combined with the other video modes above):
 {
  "message": "You have completed CASE 2",
  "actionButtons": [
    {
      "label": "Go to library",
      "color": "warning",
      "action": "show-library",
      "class": "caption font-weight-bold"
    },
    {
      "label": "Claim credit",
      "color": "success",
      "action": "show-credit",
      "class": "caption font-weight-bold"
    },
    {
      "label": "Move to substep 20",
      "color": "warning",
      "action": "changeSubStep",
      "actionValue": "20",
      "class": "caption font-weight-bold"
    },
        {
      "label": "Begin next case",
      "color": "warning",
      "action": "changeStep",
      "actionValue": "3",
      "class": "caption font-weight-bold"
    }
  ],
  "video_id": "ww55n6mfyg"
}
 */

import { mapState, mapMutations } from "vuex";
import axios from "axios";
import Confirm from "/src/components/modals/Confirm.vue";
import Player from "/src/components/player/Player.vue";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

const VIDEO_PERCENT_AS_WATCHED = 95;
const VIDEO_SECONDS_REMAINING_AS_WATCHED = 5;

export default {
  name: "StepVideo",
  components: {
    Confirm,
    Player,
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    video_id: {
      type: [String, Object],
      required: true,
      default: "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    actionButtons: {
      type: Array,
      required: false,
      default: null,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      video: {
        id: "",
      },
      startTime: 0,
      videoWatched: false,
      videoIsOptional: false,
      modal: {
        title: "",
        description: "",
        onConfirm: () => {
          console.log("not set");
        },
      },
    };
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseData,
      continueButtonEnabled: (state) => state.continueButtonEnabled,
      user: (state) => state.user,
    }),
    cVideoWatchedMessage() {
      if (this.videoWatched) {
        return "You completed this video, click Continue or play again to review.";
      }
      return "";
    },
    cMessage() {
      if (!this.videoIsOptional && !this.videoWatched) {
        return "Finish the video before Continuing.";
      } else if (this.videoIsOptional && !this.videoWatched) {
        return "Feel free to watch the video above or click Continue.";
      }
      if (this.message) {
        return this.message;
      }
      if (this.cVideoWatchedMessage === "") {
        return "When you complete the video, click Continue.";
      }
      return "";
    },
  },
  watch: {
    videoWatched(val) {
      this.toggleContinueButton(val);
    },
    videoIsOptional() {
      this.whenVideoIsOptional();
    },
  },
  created() {
    this.setVideoIdFromProp();
    this.whenVideoIsOptional();
    this.checkWatchedProgress();
  },
  methods: {
    ...mapMutations([
      "setCurrentStep",
      "setCurrentSubStep",
      "setShowCredit",
      "setShowLibrary",
      "setContinueButtonEnabled",
    ]),
    whenVideoIsOptional() {
      this.toggleContinueButton(this.videoIsOptional);
    },
    toggleContinueButton(enable) {
      if (this.continueButtonEnabled !== enable) {
        this.setContinueButtonEnabled(enable);
      }
    },
    setVideoIdFromProp() {
      if (typeof this.video_id === "string") {
        this.video.id = this.video_id;
      } else {
        const videoOptions = this.video_id;
        if (!videoOptions.op) {
          this.video.id = this.video_id.value;
          this.setVideoCustomOptions(this.video_id);
        }
        if (videoOptions.op === "range") {
          this.video.id = this.getVideoIdByRange(videoOptions);
        } else if (videoOptions.op === "value") {
          this.video.id = this.getVideoIdByValue(videoOptions);
        }
      }
    },
    getVideoIdByRange(videoOptions) {
      const valueToCompare = this.caseData[videoOptions.variable];
      for (const videoHash in videoOptions.values) {
        const values = videoOptions.values[videoHash];
        if (values.min <= valueToCompare && valueToCompare <= values.max) {
          if (values) this.setVideoCustomOptions(values);
          return videoHash;
        }
      }
      return null;
    },
    getVideoIdByValue(videoOptions) {
      const valueToCompare = this.caseData[videoOptions.variable];
      for (const videoHash in videoOptions.values) {
        let value = videoOptions.values[videoHash];
        let options;
        if (typeof value !== "string") {
          options = value;
          value = value.value;
        }
        if (value == valueToCompare) {
          if (options) this.setVideoCustomOptions(options);
          return videoHash;
        }
      }
      return null;
    },
    setVideoCustomOptions(options) {
      if (options.isOptional) {
        this.videoIsOptional = true;
      }
    },
    customButtonClick(button) {
      if (!button.action) {
        return;
      }
      if (button.action === "changeSubStep") {
        this.setCurrentSubStep(parseInt(button.actionValue));
      } else if (button.action === "changeStep") {
        this.setCurrentStep(parseInt(button.actionValue));
        this.setCurrentSubStep(parseInt(1));
      } else if (button.action === "show-credit") {
        this.modalShowCredits();
      } else if (button.action === "show-library") {
        this.modalShowLibrary();
      }
    },
    modalShowCredits() {
      this.setShowCredit(true);
      // this.modal.title = "Claim Credit";
      // this.modal.description =
      //   "Are you sure you want to exit to Claim Credit? This will complete your lesson!";
      // this.modal.onConfirm = () => {
      //   this.setShowCredit(true);
      // };
      // this.$refs["confirmModal"].setVisible(true);
    },
    modalShowLibrary() {
      this.setShowLibrary(true);
      // this.modal.title = "See Library";
      // this.modal.description =
      //   "Are you sure you want to go to the Library? Proceeding will stop your progress in the case!";
      // this.modal.onConfirm = () => {
      //   this.setShowLibrary(true);
      // };
      // this.$refs["confirmModal"].setVisible(true);
    },
    playerStateChanged(playerData) {
      if (["pause", "beforeDestroy"].indexOf(playerData.event) > -1) {
        this.saveWatchedProgress(playerData);
      }
    },
    watchedSecondsChanged(videoOptions) {
      if (
        !this.videoWatched &&
        videoOptions.secondsWatched >=
          videoOptions.duration - VIDEO_SECONDS_REMAINING_AS_WATCHED
      ) {
        this.videoWatched = true;
      }
    },
    watchedPercentChanged(videoOptions) {
      // Rule of set video as watched when above VIDEO_PERCENT_AS_WATCHED replaced to VIDEO_SECONDS_REMAINING_AS_WATCHED
      // if (videoOptions.percentWatched >= VIDEO_PERCENT_AS_WATCHED) {
      //   this.videoWatched = true;
      // }
      this.watchedSecondsChanged(videoOptions);
    },
    async saveWatchedProgress(playerData) {
      const payload = {
        seconds_watched: playerData.secondsWatched,
        watched: this.videoWatched,
      };
      try {
        await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/videos-users/${playerData.videoId}/${this.user.uuid}`,
          payload
        );
      } catch (err) {
        console.log(err);
      }
    },
    async checkWatchedProgress() {
      let videoUserData;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/videos-users/${this.video.id}/${this.user.uuid}`
        );
        videoUserData = response.data;
      } catch (err) {
        console.log(err);
      }

      if (!videoUserData) {
        return;
      }

      if (videoUserData.watched) {
        this.videoWatched = true;
      }

      if (videoUserData.seconds_watched) {
        this.startTime = videoUserData.seconds_watched;
      }
    },
  },
};
</script>
