<template>
  <v-dialog
    v-if="currentSubStep > 1"
    v-model="visible"
    persistent
    max-width="600px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!isFloating"
        color="yellow"
        fab
        small
        v-bind="attrs"
        v-on="on"
        class="ml-2 mr-2"
      >
        <v-icon color="green darken-2">
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        style="z-index: 9; left: 100px; height: 40px; width: 40px;"
        color="yellow"
        fab
        fixed
        bottom
        left
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="green darken-2">
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Notes</span>
      </v-card-title>

      <v-card-text class="text-area-container">
        <v-textarea
          filled
          label="Type your notes here."
          required
          hide-details
          v-model="currentNote"
          @keydown.enter="saveNotes"
        ></v-textarea>
      </v-card-text>

      <v-card-text class="list-container">
        <v-card class="mx-auto mt-1" tile>
          <v-list shaped flat v-if="notes.length > 0">
            <v-subheader>List of notes</v-subheader>
            <v-list-item-group v-model="selectedNote" color="success">
              <v-list-item
                v-for="(note, i) in notes"
                :key="i"
                @click="selectedNoteList(i)"
              >
                <v-list-item-icon>
                  <v-icon v-text="'mdi-book'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="note.text"> </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <confirm
                    buttonIcon="mdi-delete"
                    description="Delete this note?"
                    :buttonConfirmCallback="
                      () => {
                        deletedNoteList(i);
                      }
                    "
                  ></confirm>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-snackbar v-model="alert.show" :timeout="2000" color="primary" text>
          {{ alert.text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="alert.show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-spacer></v-spacer>
        <v-btn
          v-show="noteForUpdate"
          color="green darken-1"
          text
          @click="clearCurrentNote()"
        >
          New Note
        </v-btn>
        <v-btn color="blue darken-1" text @click="closeNotes">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveNotes">
          <span v-if="!noteForUpdate">Save</span>
          <span v-else>Edit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Confirm from "/src/components/modals/Confirm.vue";
export default {
  name: "Notes",
  components: {
    Confirm,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: {},
    },
    currentStep: {
      type: Number,
      required: true,
      default: 1,
    },
    currentSubStep: {
      type: Number,
      required: true,
      default: 1,
    },
    currentTitle: {
      type: String,
      required: false,
      default: "",
    },
    isFloating: {
      type: Boolean,
      defautl: false,
      reuired: false,
    },
  },
  data() {
    return {
      visible: false,
      selectedNote: null,
      currentNote: "",
      noteForUpdate: false,
      idNoteForUpdate: 0,
      notes: [],
      alert: {
        show: false,
        text: "Note deleted",
      },
    };
  },
  watch: {
    visible(newValue) {
      if (!newValue) {
        this.clearCurrentNote();
      }
    },
  },
  beforeMount() {
    this.fetchNotes();
  },
  methods: {
    async fetchNotes() {
      let uuid = this.user.uuid;
      let notesApi;
      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_ENDPOINT}/notes`,
          {
            params: { uuid, active: 1 },
          }
        );
        notesApi = response.data.data;
      } catch (err) {
        console.log(err);
      }
      let notes = new Array();
      notesApi.forEach(function(element) {
        let note = new Array();
        note.id = element.id;
        note.text = element.title + ": " + element.note;
        notes.push(note);
      });
      this.notes = notes;
    },
    closeNotes() {
      this.visible = false;
      this.currentNote = "";
    },
    getPayload() {
      return {
        uuid: this.user.uuid,
        id_step: this.currentStep,
        id_substep: this.currentSubStep,
        title: this.currentTitle
          ? this.currentTitle
          : `Note ${this.currentSubStep}`,
        fName: this.user.fName,
        lName: this.user.lName,
        email: this.user.email,
        note: this.currentNote,
        active: 1,
        deleted: 0,
      };
    },
    saveNotes() {
      if (this.noteForUpdate) {
        let idNote = this.notes[this.idNoteForUpdate].id;
        this.notes[this.idNoteForUpdate].text = this.currentNote;
        const payload = this.getPayload();
        axios
          .put(`${process.env.VUE_APP_API_ENDPOINT}/notes/` + idNote, payload)
          .then((response) => {
            this.currentNote = "";
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const payload = this.getPayload();
        axios
          .post(`${process.env.VUE_APP_API_ENDPOINT}/note`, payload)
          .then((response) => {
            let note = new Array();
            note.id = response.data.data.id;
            note.text = this.currentTitle + ": " + this.currentNote;
            this.notes.push(note);
            this.currentNote = "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    clearCurrentNote() {
      this.currentNote = "";
      this.noteForUpdate = false;
      this.selectedNote = null;
    },
    selectedNoteList(i) {
      this.noteForUpdate = true;
      this.idNoteForUpdate = i;
      this.currentNote = this.notes[i].text;
    },
    deletedNoteList(i) {
      this.clearCurrentNote();
      let id = this.notes[i].id;
      this.notes.splice(i, 1);
      var data = "";

      var config = {
        method: "put",
        url: `${process.env.VUE_APP_API_ENDPOINT}/softDelete/${id}`,
        headers: {},
        data: data,
      };

      const self = this;
      axios(config)
        .then(function(response) {
          self.showAlert("Note deleted!");
        })
        .catch(function(error) {
          self.showAlert("Failed to delete");
          console.log(error);
        });
    },
    showAlert(text) {
      this.alert.text = text;
      this.alert.show = true;
    },
  },
};
</script>

<style scoped>
.text-area-container {
  height: 220px;
}

.list-container {
  height: 400px;
}
</style>
