<template>
  <span>
    <v-btn
      v-if="!isSending"
      class="white--text caption font-weight-bold"
      large
      elevation="2"
      rounded
      color="blue"
      @click="emailAndSavingNotes()"
    >
      Email My Notes
    </v-btn>
    <v-btn
      v-else
      class="white--text caption font-weight-bold"
      large
      elevation="2"
      rounded
      color="blue"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-btn>
    <v-snackbar v-model="alert.show" :timeout="5000" color="primary" text>
      {{ alert.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="alert.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import axios from "axios";
export default {
  name: "EmailNotes",
  props: {
    user: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      isSending: false,
      alert: {
        show: false,
        text: "",
      },
    };
  },
  methods: {
    emailAndSavingNotes() {
      this.isSending = true;
      var data = JSON.stringify({
        uuid: this.user.uuid,
        firstName: this.user.fName,
        lastName: this.user.lName,
        email: this.user.email,
      });

      var config = {
        method: "post",
        url: `${process.env.VUE_APP_API_ENDPOINT}/mailingNotes`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const self = this;
      axios(config)
        .then(function(response) {
          if (response.status === 200) {
            self.showAlert("Mail sended to " + self.user.email);
          } else {
            self.showAlert(response.data);
          }
        })
        .catch(function(error) {
          console.error(error);
          self.showAlert("Failed to send.");
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    showAlert(text) {
      this.alert.text = text;
      this.alert.show = true;
    },
  },
};
</script>

<style scoped></style>
