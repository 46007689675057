<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent max-width="290">
      <template v-if="showButton" v-slot:activator="{ on, attrs }">
        <v-btn v-if="buttonLabel" v-bind="attrs" v-on="on">
          {{ buttonLabel }}
        </v-btn>
        <div v-else v-bind="attrs" v-on="on">
          <v-btn icon>
            <v-icon color="grey lighten-1">{{ buttonIcon }}</v-icon>
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="buttonCancelColor" text @click="setVisible(false)">
            {{ buttonCancelLabel }}
          </v-btn>
          <v-btn :color="buttonConfirmColor" text @click="callConfirm">
            {{ buttonConfirmLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Confirm",
  props: {
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: null,
    },
    buttonIcon: {
      type: String,
      required: false,
      default: "mdi-delete",
    },
    title: {
      type: String,
      required: false,
      default: "Delete",
    },
    description: {
      type: String,
      required: false,
      default: "Are you sure?",
    },
    buttonConfirmLabel: {
      type: String,
      required: false,
      default: "Delete",
    },
    buttonConfirmColor: {
      type: String,
      required: false,
      default: "red darken-1",
    },
    buttonConfirmCallback: {
      type: Function,
      required: false,
      default: null,
    },
    buttonCancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    buttonCancelColor: {
      type: String,
      required: false,
      default: "blue darken-1",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {},
  methods: {
    setVisible(value) {
      this.visible = value;
    },
    callConfirm() {
      if (this.buttonConfirmCallback) {
        this.buttonConfirmCallback();
      }
      this.setVisible(false);
    },
  },
};
</script>

<style scoped></style>
