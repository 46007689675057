<template>
  <step-template :subStep="subStep">
    <v-card-text>
      <div style="height: 400px">
        <br />
        <h4
          v-if="avatar.random === false"
          class="pb-5 text-center"
          v-html="subStep.mainSection.content.mainText"
        ></h4>
        <h4 v-else class="pt-5 pb-5 text-center">
          The
          <span style="color:#ff9800;"><b>RANDOMIZER</b></span>
          has selected: {{ tempValue }}.
        </h4>
        <br />
        <p class="mr-5 ml-5">
          <v-slider
            v-model="tempValue"
            :rules="subStep.mainSection.content.rules"
            :min="subStep.mainSection.content.min"
            :max="subStep.mainSection.content.max"
            :step="subStep.mainSection.content.step"
            persistent-hint
            thumb-label="always"
            color="blue"
            ticks
            :disabled="avatar.random"
            @change="sliderChanged"
          ></v-slider>
          <v-range-slider
            :tick-labels="subStep.mainSection.content.ticksLabels"
            v-model="subStep.mainSection.content.range"
            min="4"
            max="12"
            step="2"
            tick-size="5"
            ticks
            :disabled="true"
          >
          </v-range-slider>
        </p>
        <v-row>
          <v-col v-if="imgSrc" cols="4">
            <p class="pt-5 pb-5 text-center">
              <v-img max-height="150" max-width="250" :src="imgSrc"></v-img>
            </p>
          </v-col>
          <v-col cols="8">
            <h3 v-if="avatar.random === false" class="pt-5 pb-5 text-center">
              {{ subStep.mainSection.content.subText }}
              {{ sliderValue }}
            </h3>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-slider'
 - update the row field component_props as a JSON like:
{
  "variables": { "vmodel": "CUSTOM_VARIABLE_NAME", "CUSTOM_VARIABLE_NAME": CUSTOM_VARIABLE_DEFAULT_VALUE },
  "imgSrc": "/img/A1C.95cb81e4.jpg"
}
 */

import { mapState, mapActions, mapMutations } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepSlider",
  components: {
    StepTemplate,
  },
  props: {
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    imgSrc: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      sliderValue: 0,
      tempValue: 0,
      firstSet: false,
    };
  },
  computed: {
    ...mapState({
      avatar: (state) => state.avatar,
      caseData: (state) => state.caseData,
    }),
  },
  watch: {
    sliderValue(val) {
      if (this.avatar.random) {
        this.firstSet = true;
        this.setSliderValue(val);
      } else if (this.variables[this.variables.vmodel] !== val) {
        this.firstSet = true;
      }
      if (this.firstSet) {
        this.manageContinueButton();
      } else {
        this.firstSet = true;
      }
    },
  },
  created() {
    this.manageContinueButton();
    this.setInitialSliderValue();
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled"]),
    ...mapActions(["setCaseDataValue"]),
    manageContinueButton() {
      if (this.sliderValue === 0) this.setContinueButtonEnabled(false);
      else this.setContinueButtonEnabled(true);
    },
    setInitialSliderValue() {
      const currentStorageValue = this.caseData[this.variables.vmodel];
      if (currentStorageValue) {
        this.sliderValue = currentStorageValue;
        this.tempValue = currentStorageValue;
      } else {
        this.sliderValue = this.variables[this.variables.vmodel];
        this.tempValue = this.variables[this.variables.vmodel];
      }
    },
    setSliderValue(val) {
      this.sliderValue = val;
      this.setCaseDataValue({ key: this.variables.vmodel, value: val });
    },
    sliderChanged(val) {
      this.setSliderValue(val);
    },
  },
};
</script>
