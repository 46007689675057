<template>
  <step-template :subStep="subStep">
    <h5 class="pt-5 text-center">
      {{ question }}
      <v-row v-if="bannerSrc">
        <v-col>
          <img
            :lazy-src="bannerSrc"
            contain
            :src="bannerSrc"
            style="margin: 0 auto; max-height: 200px;"
          />
        </v-col>
      </v-row>
    </h5>

    <v-radio-group v-model="selected">
      <v-alert
        v-for="(option, key) in options"
        :key="key"
        class="m-md"
        :border="borderPosition"
        colored-border
        :color="cColor(key)"
        elevation="2"
        v-ripple="{ center: true }"
      >
        <v-row class="cursor-pointer" @click="select(option)">
          <v-col cols="1">
            <v-radio
              :value="option.value"
              :color="cColor(key)"
              :ripple="false"
              off-icon="mdi-checkbox-blank-circle-outline"
              on-icon="mdi-check-circle-outline"
            ></v-radio>
          </v-col>
          <v-col cols="11">
            <div v-if="option.title" class="text-h6">{{ option.title }}</div>
            <div v-if="option.content">{{ option.content }}!</div>
          </v-col>
        </v-row>
      </v-alert>
    </v-radio-group>
  </step-template>
</template>

<script>
/**
 * To use:
 - in the database, go to table substeps
 - find the substep row that you want this component
 - update the row field component='step-multiple-choice'
 - update the row field component_props as a JSON like:
 {
  "variables": { "vmodel": "CUSTOM_VARIABLE_NAME" },
  "color": "orange",
  "colorAccent": false,
  "borderPosition": "left",
  "bannerSrc": "http://localhost:8080/img/avatar_real_5.52d005c6.jpg",
  "question": "Who is the best power ranger?",
  "options": [
    {
      "value": "1",
      "title": "In consectetuer turpis",
      "content": "Aliquam eu nunc. Fusce commodo aliquam arcu. In consectetuer turpis ut velit. Nulla facilisi.. Morbi mollis tellus ac sapien. Fusce vel dui. Praesent ut ligula non mi varius sagittis. Vivamus consectetuer hendrerit lacus. Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi."
    },
    {
      "value": "2",
      "title": "What is Lorem Ipsum",
      "content": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    }
  ]
}
 */
import { mapState, mapMutations, mapActions } from "vuex";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "StepMultipleChoice",
  components: {
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    question: {
      type: String,
      required: true,
      default: "...",
    },
    options: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    variables: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    color: {
      type: String,
      required: false,
      default: "orange",
    },
    colorAccent: {
      type: Boolean,
      required: false,
      default: true,
    },
    borderPosition: {
      type: String,
      required: false,
      default: "left",
    },
    bannerSrc: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseData,
    }),
    cColor() {
      return (key) => {
        let accent;
        if (this.colorAccent) accent = `accent-${key + 1}`;
        return `${this.color} ${accent}`;
      };
    },
  },
  watch: {
    selected(val) {
      if (val) {
        this.setContinueButtonEnabled(true);
      } else {
        this.setContinueButtonEnabled(false);
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["setContinueButtonEnabled"]),
    ...mapActions(["setCaseDataValue"]),
    init() {
      this.loadPreviusSelectedChoice();
      this.setContinueButtonEnabled(false);
    },
    select(option) {
      this.setCaseDataValue({
        key: this.variables.vmodel,
        value: option.value,
        fullObject: option,
      });
      this.selected = option.value;
      this.setContinueButtonEnabled(true);
    },
    loadPreviusSelectedChoice() {
      const currentValue = this.caseData[this.variables.vmodel];
      if (!currentValue) return;
      const currentOption = this.options.find((option) => {
        if (option.value == currentValue) {
          return true;
        }
      });
      this.selected = currentOption.value;
    },
  },
};
</script>
