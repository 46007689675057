<template>
  <step-template v-if="videos.length > 0" :subStep="subStep">
    <v-row class="ma-5">
      <v-col cols="12">
        <p>
          Welcome to the Library. Here you'll find video resources, some of
          which you saw in your patient case(s), as well as additional topics of
          interest in managing patients with T2D.
          <br />
          When you've completed reviewing these resources, click the Claim
          Credit button.
        </p>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-col cols="12">
        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="(section, sKey) in videosBySections"
            :key="sKey"
          >
            <v-expansion-panel-header>
              {{ sKey }}
              <template
                v-if="sKey === 'Additional Topics of Interest'"
                v-slot:actions
              >
                <v-icon color="red lighten-2">
                  mdi-star
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="(video, vKey) in section" :key="vKey">
                <v-col :cols="`${!isMobile ? 4 : 12}`">
                  <v-img
                    :lazy-src="
                      `https://fast.wistia.com/embed/medias/${video.id}/swatch`
                    "
                    max-height="150"
                    max-width="250"
                    :src="videosThumbs[video.id]"
                    @click="openModal(video)"
                  ></v-img>
                </v-col>
                <v-col :cols="`${!isMobile ? 8 : 12}`">
                  <v-card
                    outlined
                    color="transparent"
                    class="flex-card"
                    height="100%"
                  >
                    <v-card-text
                      ><h6>
                        {{ video.title_public }}
                      </h6></v-card-text
                    >
                    <v-btn
                      color="red lighten-2 mt-4"
                      dark
                      @click="openModal(video)"
                    >
                      Open Video
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-btn
        outlined
        color="orange"
        :rounded="true"
        class="border"
        @click="returnToSteps"
        >return to case</v-btn
      >
    </v-row>
    <modal-video
      ref="modal-video"
      :user="user"
      :video="modal.video"
      :title="modal.title"
    />
  </step-template>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import ModalVideo from "/src/components/modals/ModalVideo.vue";
import StepTemplate from "/src/components/steps/StepTemplate.vue";

export default {
  name: "Library",
  components: {
    ModalVideo,
    StepTemplate,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    subStep: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    videos: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      videosSections: {
        "Patient Profile Selection": [],
        A1C: [],
        BMI: [],
        Comorbidity: [],
        "Clinical Metrics": [],
        "Modifiable Behaviors": [],
        "Social Determinants of Health": [],
        "GLP-1 RA Selection": [],
        "Mitigating Adverse Effects": [],
        "Additional Topics of Interest": [],
      },
      videosBySections: {},
      videosThumbs: {},
      modal: {
        video: {},
        title: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    videos(val) {
      this.setUpVideosBySections();
    },
  },
  beforeMount() {
    this.setUpVideosBySections();
    this.trackingAction("Library opened");
  },
  beforeDestroy() {
    this.trackingAction("Library closed");
  },
  methods: {
    ...mapMutations(["setShowLibrary"]),
    ...mapActions(["trackingAction"]),
    returnToSteps() {
      this.setShowLibrary(false);
    },
    openModal(video) {
      this.modal.title = video.title;
      this.modal.video = video;
      this.$refs["modal-video"].setVisible(true);
    },
    setUpVideosBySections() {
      if (this.videos.length === 0) {
        return;
      }

      for (const key in this.videos) {
        const v = this.videos[key];
        this.thumbSrc(v.id);
        if (this.videosSections[v.library_section]) {
          this.videosSections[v.library_section].push(v);
        }
      }

      this.videosBySections = this.videosSections;
    },
    thumbSrc(id) {
      var config = {
        method: "get",
        url: `https://fast.wistia.com/embed/medias/${id}.jsonp`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const self = this;
      axios(config)
        .then(function(response) {
          let data = response.data;
          data = data.split("= ")[1];
          data = data.split(";\nwindow['wistiajsonp")[0];
          try {
            const dataString = JSON.parse(data);
            self.$set(
              self.videosThumbs,
              id,
              `${dataString.media.embed_options.stillUrl}?image_crop_resized=480x270`
            );
          } catch (e) {
            console.log(
              "Failed to fetch thumb",
              `https://fast.wistia.com/embed/medias/${id}.jsonp`
            );
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>
