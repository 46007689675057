<template>
  <v-card-actions class="py-0">
    <v-chip-group show-arrows>
      <div v-for="(item, i) in steps">
        <v-chip
          :key="i"
          :color="step.id >= i + 1 ? 'green darken-2' : 'gray'"
          :text-color="step.id >= i + 1 ? 'white' : 'gray'"
          :disabled="step.id !== i + 1"
          :ripple="false"
        >
          CASE {{ i + 1 }}
        </v-chip>
        <v-chip
          v-if="step.id === i + 1"
          color="green"
          text-color="white"
          :ripple="false"
          small
        >
          {{ subStep.id }}
        </v-chip>
      </div>
      <v-chip
        :color="libraryActive ? 'green darken-2' : 'gray'"
        :text-color="libraryActive ? 'white' : 'gray'"
        :disabled="!libraryActive"
        :ripple="!libraryActive"
        @click="showLibrary()"
      >
        LIBRARY
      </v-chip>
      <v-chip
        :color="libraryActive ? 'green darken-2' : 'gray'"
        :text-color="libraryActive ? 'white' : 'gray'"
        :disabled="!libraryActive"
        :ripple="!libraryActive"
        @click="showCredits()"
      >
        CLAIM CREDIT
      </v-chip>
    </v-chip-group>
  </v-card-actions>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Stepper",
  props: {
    step: {
      type: Object,
      required: true,
    },
    subStep: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  computed: {
    libraryActive() {
      return this.currentStep > 1 ? true : false;
    },
  },
  methods: {
    ...mapMutations(["setShowCredit", "setShowLibrary"]),
    showCredits() {
      this.setShowCredit(true);
    },
    showLibrary() {
      this.setShowLibrary(true);
    },
  },
};
</script>
